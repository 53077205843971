import React from 'react';
import { useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Img from 'gatsby-image';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/Layouts/layout';
import SEO from '../components/seo';
import ArticleTile from '../components/ArticleTile/ArticleTile';

const NewsPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			backgroundersImg: file(relativePath: { eq: "backgroundersThumb.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			eventsImg: file(relativePath: { eq: "eventsThumb.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			mediaImg: file(relativePath: { eq: "newsThumb.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			newsReleaseImg: file(relativePath: { eq: "newspaperThumb.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			header={
				<span className='bold'>
					<FormattedMessage id='nav.news' />
				</span>
			}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.newsDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.news' })}
			/>
			<Container>
				<Row align='stretch'>
					<Col lg={3} md={6}>
						<ArticleTile
							id='backgrounders'
							image={<Img fluid={data.backgroundersImg.childImageSharp.fluid} alt='' />}
							linkPath='/news-and-events/backgrounders/'
							buttonTxt='View'
						>
							<h3>
								<FormattedMessage id='nav.backgrounders' />
							</h3>
						</ArticleTile>
					</Col>

					<Col lg={3} md={6}>
						<ArticleTile
							id='newsReleases'
							image={<Img fluid={data.newsReleaseImg.childImageSharp.fluid} alt='' />}
							linkPath='/news-and-events/news-releases/'
							buttonTxt='View'
						>
							<h3>
								<FormattedMessage id='nav.newsReleases' />
							</h3>
						</ArticleTile>
					</Col>

					<Col lg={3} md={6}>
						<ArticleTile
							id='events'
							image={<Img fluid={data.eventsImg.childImageSharp.fluid} alt='' />}
							linkPath='/news-and-events/events/'
							buttonTxt='View'
						>
							<h3>
								<FormattedMessage id='nav.events' />
							</h3>
						</ArticleTile>
					</Col>

					<Col lg={3} md={6}>
						<ArticleTile
							id='mediaInquiries'
							image={<Img fluid={data.mediaImg.childImageSharp.fluid} alt='' />}
							linkPath='/news-and-events/media-inquiries/'
							buttonTxt='View'
						>
							<h3>
								<FormattedMessage id='nav.mediaInquiries' />
							</h3>
						</ArticleTile>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default NewsPage;
